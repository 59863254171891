import React, { useEffect } from "react"
import AxiosInstance from "../../services/axiosInstance"
import { navigate } from "gatsby"

import Layout from "../Layout/layout"

const Clone = ({ cloneId, cloneType }) => {
  useEffect(() => {
    const defaultCloneType = cloneType
    if (cloneType == "pdf") {
      cloneType = "e-sign"
    }

    AxiosInstance.post(`/drive/${cloneType}/clone/${cloneId}`)
      .then(function (response) {
        navigate(`/drive/${defaultCloneType}/${response.data.driveAll}`)
      })
      .catch(function (error) {
        alert("Wrong clone url.")
        navigate("/")
      })
  }, [])

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <h1>Cloning Template</h1>
        </div>
      </div>
    </Layout>
  )
}

export default Clone

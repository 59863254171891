import * as React from "react"
import Seo from "../../../../components/seo"
import PrivateRoute from "../../../../components/privateRoute"
import ReduxWrapper from "../../../../redux/reduxWrapper"
import Clone from "../../../../components/Drive/clone"

const ClonePage = props => {
  return (
    <React.Fragment>
      <Seo title="Drive Clone" />
      <PrivateRoute
        path="/"
        component={Clone}
        location={props.location}
        cloneId={props.cloneId}
        cloneType={props.type}
      />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<ClonePage {...props} />} />
export default WrappedPage
